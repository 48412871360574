@import (inline) '../css/font-awesome.min.css';
@import (inline) '../css/reset.css';
@import "modules/_base";
@import "modules/_fonts";


body, html {
    height: 100%;
    width: 100%;
    display: block;
    font-size: 14px;
    background: @white;
    .lato();
    color: @black;
}
body {
    overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

header {
    width: 100%;
    height: 50px;
    display: block;
    position: fixed;
    background-color: @black;
    text-align: right;
    z-index: 10;
    a {
        color: @white;
        .lato();
        font-size: 1em;
        text-decoration: none;
        display: inline-block;
        margin: 8px 0px 0px 15px;
        i {
            width: 18px;
            text-align: left;
        }
    }
    @media screen and (max-width: @bp_iphone) {
        height: 100px;
        a {
            line-height: 2em;
        }
    }
}
.wrap {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto;
    height: auto;
    display: block;
    .box-sizing(border-box);
    padding: 10px 25px;
}
.slogan {
    margin-top: 50px;
    padding: 50px 25px;
    @media screen and (max-width: @bp_iphone) {
        margin-top: 100px;
    }
}
h1 {
    font-size: 8em;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.1em;
    b {
        color: @green;
    }
    @media screen and (max-width: @bp_iphone) {
        font-size:6em;
    }
}
h2 {
    font-size: 2.6em;
    line-height: 1.2em;
    padding: 0px 7px;
}
.headline {
    .wrap();
    padding: 10px 0px 0px 2px;
    height: 50px;
    h3 {
        font-size: 2.6em;
        line-height: 1.2em;
        text-transform: uppercase;
        font-weight: 700;
        color: @grey;
    }
    margin-bottom: -14px;

    position: relative;
    display: block;
    //z-index: 5;
}
.box {
    width: 50%;
    display: inline-block;
    height: auto;
    .box-sizing(border-box);
    vertical-align: top;
    .innerBox {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        background-color: @grey;
        .box-sizing(border-box);
        padding: 25px;
        min-height: 310px;
        h4 {
            font-size: 3em;
            line-height: 1.1em;
            font-weight: 300;
            margin-bottom: 25px;
            color: @green;
        }
        p {
            line-height: 1.4em;
        }
        border: solid 5px @white;
    }
    &:nth-child(3) {
        padding-right: 10px;
    }
    &:nth-child(4) {
        padding-left: 10px;
    }
    &:nth-child(5) {
        margin-top: 20px;
        padding-right: 10px;
    }
    &:nth-child(6) {
        margin-top: 20px;
        padding-left: 10px;
    }

    &.greenBorder {
        .innerBox {
            border: solid 5px @green;
        }
    }
    @media screen and (max-width: @bp_ipad) {
        width: 100%;
        padding: 0px !important;
        .innerBox {
            min-height: auto !important;
        }
    }
}
.ref{
    /*margin-bottom: -8px;
    margin-top: 25px;
    margin-left: -5px;*/
    margin: 25px;

}

.sliderWrap {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    background-color: @grey;
    height: 400px;
    .box-sizing(border-box);
    padding: 25px;
    min-height: 290px;
    margin-bottom: 50px;
    @media screen and (max-width: @bp_ipad) {
            height: 800px;
    }
}
.slides {
    opacity: 0;
    position: absolute;
    top: 25px;
    .box-sizing(border-box);
    padding: 0px 25px;
    display: table;
}
.slide1 {
    opacity: 1;
}


.arrow {
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    top: 50%;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
    .transition(all .6s ease-in-out);
    z-index: 9;
    &.left {
        left: 20px;
    }
    &.right {
        right: 20px;
    }
    &:hover {
        background: rgba(0,0,0,.3);
        color: @white;
        cursor: pointer;
    }
}
.bxSlider {
    width: 100%;
    display: block;
    height: 350px;
    @media screen and (max-width: @bp_ipad) {
            height: 700px;
    }
}
.description {
    vertical-align: top;
    width: 50%;
    height: 350px;
    display: inline-block;
    position: relative;
    .box-sizing(border-box);
    padding: 0px 50px 0px 0px;
    h4 {
        font-size: 3em;
        line-height: 1.1em;
        font-weight: 300;
        margin-bottom: 25px;
    }
    ul {
        list-style-type: circle;
        padding-left: 25px;
        li {
            color: @green;
            font-size: 1.2em;
            padding-top: 10px;
        }
    }
    @media screen and (max-width: @bp_ipad) {
        width: 100%;
        display: table-footer-group;
    }
}
.imgWrap {
    vertical-align: top;
    width: 50%;
    height: 350px;
    display: none;
    position: relative;
    opacity: 0;
    &.vis {
        opacity: 1;
        display: inline-block;
    }
    &.hoch {
        img {
            height: 470px;
            width: auto;
            display: block;
            position: relative;
            margin: 0px auto;
            margin-top: -60px;
        }
    }
    &.quer {
        img {
            height: 350px;
            width: auto;
            display: block;
            position: relative;
            @media screen and (max-width: @bp_ipad) {
                margin: 0px auto;
            }
        }
    }
    @media screen and (max-width: @bp_ipad) {
        width: 100%;
        display: table-header-group;
    }
}
.greenBorder {
    form {
        width: 70%;
        vertical-align: top;
        display: inline-block;
        .box-sizing(border-box);
        padding: 0px 15px 0px 0px;
        span {
            height: 35px;
            display: inline-block;
            vertical-align: middle;
            .flexbox();
        }
    }
    .kas_img {
        display: block;
        position: absolute;
        width: 40%;
        height: 85%;
        bottom: 0;
        right: -8%;
        img {
            width: 100%;
            height: auto;
            display: block;
            position: absolute;
            bottom: 0px;
            right: 0px;
        }
    }
    label {
        display: inline-block;
        width: 80px;
        vertical-align: top;
        text-align: left;
        padding: 0px 15px 0px 0px;
        .flex(0 0 auto);
    }
    input {
        border: 0px solid transparent;
        outline: none;
        height: 20px;
        padding: 0px 5px;
        width: 100%;
        .flex(0 1 auto);
    }
    textarea {
        height: auto;
        padding: 5px 5px;
        width: 100%;
        .flex(0 1 auto);
        border: 0px solid transparent;
        outline: none;
        resize: none;
        .box-sizing(border-box);
    }
    .nachricht {
        height: 35px;
    }
    .submit {
        margin-top: 10px;
        cursor: pointer;
    }
    button {
        border: 0px solid transparent;
        outline: none;
        height: 30px;
        background: @white;
        border-radius: 0;
        width: 100%;
    }
    .small {
        font-size: .6em;
    }
}
footer {
    margin-top: 50px;
    height: 150px;
    display: block;
    position: relative;
    background: @black;
    .transition(all .6s ease-in-out);
    a {
        color: @white;
        .lato();
        font-size: 1em;
        text-decoration: none;
        margin: 8px 0px 0px 25px;
        i {
            width: 18px;
            text-align: left;
            margin: 0px 0px 0px 25px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}

.pos, .neg {
    display: none;
}
.honeyPot {
    height: 0px !important;
    width: 0px !important;
    visibility: hidden;
    opacity: 0;
    padding: 0px !important;
}
